import React, { useEffect, useState } from "react"
import { graphql, Link } from "gatsby"
import Seo from "../components/seo"
import TecalisLogo from "../new/resources/tecalis.svg"
import rehypeRaw from "rehype-raw"
import ReactMarkdown from "react-markdown"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { initializeHubspotForm } from "../utils/gatsby-helpers"
import NewLayout from "../components/new_layout"
import CardShare from "../components/v2023/UI/Card/card-share"
import Certifications from "../components/v2023/Certifications/certifications"
import Identity from "../components/v2023/Identity/identity"
import moment from "moment"
import LinkButton from "../components/v2023/UI/LinkButton/link-button"
import { Modal } from "antd"


const WebinarPage = ({ data, pageContext, location }) => {
    const now = moment()
    const webinar = data.webinar
    const time_start = moment(`${webinar.date} ${webinar.time_start}`)
    const time_end = moment(`${webinar.date} ${webinar.time_end}`)
    const is_finished = now.isAfter(time_start)
    const homePath = pageContext.langKey === "en" ? "/" : `/${pageContext.langKey}`
    const lang = pageContext.langKey

    const [showFormModal, setShowFormModal] = useState(false)

    const elements = {
        p: ({ node, ...props }) => <p {...props} />,
        ul: ({ node, ...props }) => <ul className="check-list" {...props} />,
        li: ({ node, ...props }) => <li className="check-list__item"><span {...props} /></li>
    }

    useEffect(() => {
        if (showFormModal) {
            initializeHubspotForm(
                webinar.hubspot_form_recorded.portal_id,
                webinar.hubspot_form_recorded.form_id,
                "#form-webinar",
                webinar.hubspot_form_recorded.region,
                { webinar_name: webinar.hubspot_webinar_name }
            )
        }
    }, [showFormModal])

    return (
        <NewLayout pageContext={pageContext}>
            <Seo
                lang={pageContext.langKey}
                title={webinar.seo.title}
                description={webinar.seo.meta_description}
                translates={pageContext.translates}
                image={webinar.seo?.image_2?.localFile?.publicURL}
            />

            <main className="main main--webinar">
                <div className="main__section main__section--100 color-section purple">
                    <div className="container" data-aos="fade-up">
                        <div className="logos">
                            <Link to={homePath}>
                                <img src={TecalisLogo} alt="Tecalis" title="Tecalis" />
                            </Link>
                            {webinar.additional_logos?.map((logo, index) => (
                                <div className="logos__logo" key={index}>
                                    <GatsbyImage key={index} alt={logo.image.alternativeText} image={getImage(logo.image.localFile)} />
                                </div>
                            ))}
                        </div>

                        {is_finished ?
                            <div className="tag tag--highlight ghost">
                                <i className="icon-point"></i>
                                <span>Recorded Webinar</span>
                            </div>
                            :
                            <div className="tag tag--highlight ghost">
                                <i className="icon-live"></i>
                                <span>Live Webinar</span>
                            </div>
                        }
                        <div className="container__text">
                            <h1 className="merriweather">
                                {webinar.name}
                            </h1>
                        </div>

                        {!is_finished && webinar.button && <LinkButton button={webinar.button} className="button button--xl" />}
                        {is_finished && <>
                            <button className="button button--xl" onClick={() => setShowFormModal(true)}>{webinar.button_recorded}</button>
                            <Modal
                                centered
                                onCancel={() => setShowFormModal(false)}
                                visible={showFormModal}
                                width={"100%"}
                                footer={null}
                            >
                                <div id="form-webinar" className="form-hb form-hb--webinar" />
                            </Modal>
                        </>}

                        <div className="tile tile--white--nb">
                            <div className="tile__body">
                                <div className="tile__body__section">
                                    {is_finished &&
                                        <div className="form-group">
                                            <i className="icon-clock"></i>
                                            <span>{webinar.duration}</span>
                                        </div>
                                    }
                                    {!is_finished &&
                                        <div className="form-group">
                                            <i className="icon-calendar"></i>
                                            <span>{time_start.format("DD MMM YYYY")}</span>
                                        </div>
                                    }
                                    {!is_finished &&
                                        <div className="form-group">
                                            <i className="icon-clock"></i>
                                            <span>{`${time_start.format("HH:mm")} - ${time_end.format("HH:mm")}`}</span>
                                        </div>
                                    }
                                </div>
                                <div className="tile__body__section">
                                    <div className="avatar-group">
                                        {webinar.exhibitors.map((exhibitor, index) => (
                                            <div className="avatar avatar--lg avatar--vertical" key={index}>
                                                <div className="avatar__photo">
                                                    <GatsbyImage alt={exhibitor.photo.alternativeText} image={getImage(exhibitor.photo.localFile)} className="img-round" />
                                                </div>
                                                <div className="avatar__job">
                                                    <b>{exhibitor.first_name} {exhibitor.last_name}</b>
                                                    <span>{exhibitor.job_position}</span>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="main__section">
                    <div className="container container--content">
                            <div className="hidden-lg">
                                <CardShare
                                    lang={pageContext.langKey}
                                    title={webinar.name}
                                    url={location.href}
                                    toolbarVertical={true} />
                            </div>
                            <div className="container__text">
                                <h2>{webinar.title}</h2>
                                <div>
                                    <ReactMarkdown children={webinar.description} rehypePlugins={[rehypeRaw]} />
                                </div>
                                {webinar.photo_2 && <div>
                                    <GatsbyImage alt={webinar.photo_2.alternativeText} image={getImage(webinar.photo_2.localFile)} />
                                </div>}
                            </div>
                    </div>
                </div>
                <div className="main__section main__section--100">
                    <div className="container">
                        <div className="tile tile--white--nb">
                            <h4>{webinar.cta_title}</h4>
                            <div className="tile__body">
                                <ReactMarkdown children={webinar.cta_description} rehypePlugins={[rehypeRaw]} components={elements} />
                            </div>
                        </div>
                    </div>
                </div>
                <Certifications />
                <Identity lang={lang} />
            </main>
        </NewLayout>
    )
}

export default WebinarPage

export const eventQuery = graphql`
    query ($id: String!, $langKey: String!) {
        webinar: strapiWebinars (locale: {eq: $langKey}, id: {eq: $id}) {
            seo {
                title
                meta_description
                image_2 {
                    localFile {
                        publicURL

                    }
                }
            }
            id
            additional_logos {
                alt
                image {
                    alternativeText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                width: 150
                                placeholder: BLURRED
                                formats: [WEBP]
                            )
                        }
                    }
                }
            }
            name
            url
            date
            time_start
            time_end
            duration
            button {
                text
                url
                blank
            }
            button_recorded
            title
            description
            photo_2 {
                name
                alternativeText
                localFile {
                    childImageSharp {
                        gatsbyImageData(
                            width: 608
                            placeholder: BLURRED
                            formats: [WEBP]
                        )
                    }
                }
            }
            cta_title
            cta_description
            cta_button
            exhibitors {
                first_name
                last_name
                job_position
                photo {
                    name
                    alternativeText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                height: 80
                                width: 80
                                placeholder: BLURRED
                                formats: [WEBP]
                            )
                        }
                    }
                }
            }
            hubspot_webinar_name
            hubspot_form_recorded {
                id
                region
                portal_id
                form_id
            }
        }
        site {
            siteMetadata {
                title
                twitterHandle
            }
        }
    }
`
